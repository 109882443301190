<template>
    <div class="container">
         <div class="content">
          <!-- 个人基本信息 --> 
          <div class="content-my-base-info-box">
            <my-base-info></my-base-info>  
          </div>
          <!-- 个人中心菜单 -->
          <div class="my-center-menu-box">
            <ul>
              <li v-for="(item,index) in menuTitleData" :key="index" @click="clickMyCenterMenuHandler(index)" >
                <p class="my-center-menu-title"  :style="currentMenuIndex===index?menuTitleSelectedStyle:''">{{item}}</p>
              </li>
              <div class="my-center-menu-slider-line" :style="{left:sliderLineLeftStyle,width:sliderLineWidthStyle}"></div>
            </ul>
          </div>
          <!-- 个人中心内容 -->
          <div class="my-center-content-box">
              <router-view></router-view>
          </div>
         </div>
    </div>
  </template>
  
  <script>
import MyBaseInfo from './components/my-base-info.vue';
import { mapState } from 'vuex';
export default {
  name:"MyCenter",
  components:{
    MyBaseInfo
  },
  data(){
    return{
      menuTitleData:["我的商品","我的订单","我的收藏","账户设置","提现"]
    }
  },
  methods:{
    clickMyCenterMenuHandler(index){
      //this.currentMenuIndex = index;
      this.$store.commit('user/setIsWithdrawMenu',false);
      this.$store.commit('user/setMyCenterMenuIndex',index);
      //跳转
      switch(index){
          case 0:
              this.$router.push('/myCenter/myProduct',()=>{});
              break;
          case 1:
              this.$router.push('/myCenter/myOrder',()=>{});
              break;
          case 2:
              this.$router.push('/myCenter/myCollect',()=>{});
              break;
          case 3:
              this.$router.push('/myCenter/myAccountSetting',()=>{});
              break;
          case 4:
              this.$router.push('/myCenter/myWithdraw',()=>{
                this.$store.commit('user/setIsWithdrawMenu',true)
              });
              break;
      }
    }
  },
  mounted(){
    if(this.currentMenuIndex===0){
      this.$router.push('/myCenter/myProduct',()=>{});
    }
  },
  computed:{
    ...mapState({
      currentMenuIndex:state=>state.user.myCenterMenuIndex
    }),
    menuTitleSelectedStyle(){
      return 'font-weight:bold;color:#222222;';
    },
    sliderLineWidthStyle(){
      return this.menuTitleData[this.currentMenuIndex].length*16+'px';
    },
    sliderLineLeftStyle(){ // 50  length+100  length+150
      let tempLength = 0;
      this.menuTitleData.forEach((item,index)=>{
        if(index<this.currentMenuIndex){
          tempLength+=this.menuTitleData[index].length*16;
        }
      })
      let ttempLength = this.currentMenuIndex===0?0:tempLength
      return ttempLength+(this.currentMenuIndex+1)*50+'px';
    }
  }
}
</script>
  
  <style lang="less" scoped>
  .container{
      width: calc(100vw);
      background: #F5F5F5;
      .content{
        width: calc(100vw);
        background: #F5F5F5;
        .content-my-base-info-box{
          background: #FFFFFF;
          padding-top: 30px;
          padding-bottom: 30px;
        }
        .my-center-menu-box{
          margin: 0 auto;
          width: 1200px;
          height: 50px;
          background: #FFFFFF;
          border-radius: 8px 8px 0px 0px;
          ul{
            margin-top: 20px;
            width: 1200px;
            height: 50px;
            list-style-type: none;
            display: flex;
            cursor: default;
            position: relative;
            li{
              list-style: none;
              margin-left: 50px;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 50px;
              .my-center-menu-title{
                height: 16px;
                font-family: MicrosoftYaHei, MicrosoftYaHei;
                font-weight: bold;
                font-size: 16px;
                color: #666666;
                line-height: 16px;
                text-align: left;
                font-style: normal;
              }
              &:hover .my-center-menu-title{
                font-weight: bold;
                color: #222222;
                // cursor: pointer;
              }
            }
            .my-center-menu-slider-line{
              position: absolute;
              left: 50px;
              bottom: 0px;
              width: 64px;
              height: 1px;
              background: #171717;
              transition: all 0.3s ease-in-out;
            }
          }
        }
        .my-center-content-box{
          margin: 0 auto;
          width: 1200px;
          background: #FFFFFF;
          border-radius: 0px 0px 8px 8px;
        }
      }
  }
  
  </style>